import { AuthorizationType } from "@samacare/graphql";
import ROUTE_PATHS from "../routes/ROUTE_PATHS";

const getAuthorizationRoute = (authorization, portalIsLegacy) => {
  if (authorization.isReferral) {
    return ROUTE_PATHS.REFERRAL.path;
  }

  if (
    authorization.portalKey ||
    authorization.PortalId ||
    authorization.type === AuthorizationType.Portal
  ) {
    if (portalIsLegacy === false) {
      return ROUTE_PATHS.PORTAL_AUTH_NEW.path;
    }
    return ROUTE_PATHS.PORTAL_AUTHORIZATION.path;
  }

  if (
    authorization.type === CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key
  ) {
    return ROUTE_PATHS.MUTLI_PARTY_AUTH.path;
  }

  return ROUTE_PATHS.FORM_AUTHORIZATION.path;
};

export default getAuthorizationRoute;
