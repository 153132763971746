import { DeepPartial } from "utility-types";
import {
  AnyAction,
  Dispatch,
  Store as OriginalStore,
  applyMiddleware,
  createStore,
} from "redux";
import {
  TypedUseSelectorHook,
  useDispatch as originalUseDispatch,
  useSelector as originalUseSelector,
} from "react-redux";
import { routerMiddleware } from "connected-react-router";
import { History } from "history";
import { composeWithDevTools } from "redux-devtools-extension";

import createRootReducer from "./reducers";

export interface State {
  form: {
    currentStep: number;
    disabled: boolean;
    portalTitle: string;
    highlightRequiredFields: boolean;
    attachments: { awsKey: string }[];
    results: {
      LOMN_MARKDOWN?: string;
      APPEAL_MARKDOWN?: string;
      USE_APPEAL?: boolean;
      HCPCS_0?: string;
      PRESCRIBER_FIRST_NAME?: string;
      PRESCRIBER_LAST_NAME?: string;
      PRESCRIBER_PAYER_ASSIGNED_PROVIDER_ID?: string;
      PRESCRIBER_SUBMITTER_ID?: string;
      PRESCRIBER_SPECIALTY_CODE?: string;
      PRESCRIBER_NPI?: string;
      PRESCRIBER_SPECIALTY?: string;
      FACILITY_NAME?: string;
      PRESCRIBER_ADDRESS?: string;
      PRESCRIBER_CITY?: string;
      PRESCRIBER_STATE?: string;
      PRESCRIBER_ZIP?: string;
      PRESCRIBER_OFFICE_TIN?: string;
      PRESCRIBER_OFFICE_NPI?: string;
      LOCATION_NAME?: string;
      REQUESTED_BY_PHONE?: string;
      REQUESTED_BY_EXTENSION?: string;
      REQUEST_DESCRIPTION?: string;
      PATIENT_FIRST_NAME?: string;
      PATIENT_LAST_NAME?: string;
      PATIENT_DOB?: string;
      INSTITUTION_PATIENT_ID?: string;
      INSURANCE_COMPANY?: string;
      INSURANCE_STATE?: string;
      PRIMARY_DRUG_NAME?: string;
      SERVICE_TYPE_CODE?: string;
      emailUserOnChange?: string;
      DRUG_CODE_TYPE?: string;
      PRIMARY_NDC?: string;
    };
    segmentLoading: boolean;
  };
}

export type InitialState = DeepPartial<State>;

export type Action = AnyAction;

export type Store = OriginalStore<State, Action>;

export const useSelector: TypedUseSelectorHook<State> = originalUseSelector;

export const useDispatch: () => Dispatch<Action> = originalUseDispatch;

const configureStore = (
  initialState: InitialState = {},
  history: History
): Store => {
  // Middleware order matters
  const middlewares = [routerMiddleware(history)];
  const enhancers = applyMiddleware(...middlewares);
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeWithDevTools(enhancers)
  );

  return store;
};

export default configureStore;
