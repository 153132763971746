import { Authorization } from "@samacare/graphql";
import { AlertManager } from "react-alert";

export const sendMsgToBgScript = async (
  msg:
    | {
        type: "CognitoMsg";
        data?: { type: string; data?: string | Record<string, any> };
      }
    | { type: "SCAuthSuccessful" }
    | { type: "CheckConnection" }
    | {
        type: "PortalAuthorizationData";
        data: { url: string; draftedPA: Authorization };
      }
) => {
  try {
    const { WEB_EXTENSION_IDS } = window.CONFIG.CONSTANTS;

    const webExtensionId = (() => {
      const rerouteProdWebAppMessagesToWebExtensionEnv = localStorage.getItem(
        "rerouteProdWebAppMessagesToWebExtensionEnv"
      );

      if (rerouteProdWebAppMessagesToWebExtensionEnv) {
        const isProduction = window.location.href.includes(
          "provider.samacare.com"
        );
        if (
          rerouteProdWebAppMessagesToWebExtensionEnv === "staging" &&
          isProduction
        )
          return WEB_EXTENSION_IDS.staging;
        else if (
          rerouteProdWebAppMessagesToWebExtensionEnv === "dev" &&
          isProduction
        )
          return WEB_EXTENSION_IDS.dev;

        // if this flag is set, we want only production environment be talking to the web extension
        return "";
      }

      if (window.location.href.includes("provider.samacare.com")) {
        return WEB_EXTENSION_IDS.production;
      } else if (window.location.href.includes("app.samacare.com")) {
        return WEB_EXTENSION_IDS.staging;
      } else if (window.location.href.includes("app.demo.samacare.com")) {
        return WEB_EXTENSION_IDS.demo;
      }
      return WEB_EXTENSION_IDS.dev;
    })();

    // TODO:
    // - when publishing to the Edge or Firefox store, update the below condition to include the respective browser
    if ((window as any).chrome && (window as any).chrome.runtime) {
      return (window as any).chrome.runtime.sendMessage(webExtensionId, msg);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error in sending message to bgScript", err);
  }
};

// Custom function to substitute Promise.any,
// bc we are compiling our code to ES2015 and Promise.any is not supported
async function customPromiseAny<T>(promises: Promise<T>[]): Promise<T> {
  return new Promise((resolve, reject) => {
    let rejectedCount = 0;
    const errors: Error[] = [];

    promises.forEach((promise, index) => {
      promise.then(
        (value) => {
          resolve(value);
        },
        (error: Error) => {
          errors[index] = error;
          rejectedCount++;
          if (rejectedCount === promises.length) {
            reject(new Error("All promises were rejected"));
          }
        }
      );
    });
  });
}

export const launchPortalAuth = async ({
  authorization,
  alert,
  backupUrl,
}: {
  authorization: Authorization;
  alert: AlertManager;
  backupUrl?: string;
}) => {
  const steps = 3;
  for (let i = 1; i <= steps; i++) {
    const response = await customPromiseAny([
      new Promise((resolve) => setTimeout(() => resolve("NotConnected"), 150)),
      sendMsgToBgScript({ type: "CheckConnection" }),
    ]);
    if (response === "Connected") break;
    if (response === "NotConnected" && i === steps) {
      alert.error(
        `Portal did not launch. Please try again after reload.
          If the problem persists, please contact support.`
      );
      setTimeout(() => window.location.reload(), 4000);
    }
  }

  await sendMsgToBgScript({
    type: "PortalAuthorizationData",
    data: {
      url: authorization.portal?.loginUrl ?? backupUrl ?? "about:blank",
      draftedPA: authorization,
    },
  });
};
